export default function getLanguage(){
    const cookieLang = this.rest.getCookie("language");
    const defaultLang = this.param.language;
    if(cookieLang){
        return cookieLang
    }
    if(defaultLang){
        return defaultLang
    }
    return "cs_CZ";
}