/**
 * Volá se jak z pravého panelu (external-events) tak i přímo z kalendáře calBody
 *
 * @param actType
 * @param status
 * @returns {string} Classy pro element
 */
export default function calGetClassName(actType, status){
    var className = "";

    if (actType === 'Meetings') {
        switch (status) {
            case 'Held':
                className = 'meetingHeld';
                break;
            case 'Not Held':
                className = 'meetingNotHeld';
                break;
            case 'Confirmed':
                className = 'meetingConfirmed';
                break;
            default:
                className = 'meetingPlanned';
                break;
        }
    } else {
        switch (status) {
            case 'Planned':
                className = 'eventInProgress';
                break;
            case 'Held':
            case 'Completed':
                className = 'eventSuccess';
                break;
            case 'Not Held':
            case 'Deferred':
                className = 'eventFailed';
                break;
            default:
                className = 'eventInProgress';
                break;
        }
    }

    return className
}
