import React from "react";
import PureComponent from "../../../pure";
import Relate from "../../../formElements/Relate";
import sAction from 'sAction';

export default class HiddenRelate extends PureComponent {
    onChange(e) {
        const props = this.props;
        if (e.id) {
            sAction.dsClear();
            sAction.dsAdd('set', props.way + '/customData/customLines/lines/' + props.row + '/' + props.relateName, e.name);
            sAction.dsAdd('set', props.way + '/customData/customLines/lines/' + props.row + '/' + props.relateDef.get('id_name'), e.id);
            sAction.dsProcess();
        }
        this.props.onChange({name: e.name, id: e.id});
    }

    render() {
        const props = this.props;
        let key = 'name';
        let defaultValue = props.defaultValue;

        return (
            <React.Fragment>
                <Relate
                    key={key}
                    newRecord={false}
                    buttons={[]}
                    containerClassName={'customLinesCell customLinesRelate ' + props.extraClass}
                    containerStyle={props.style}
                    callback={(e) => this.onChange(e)}
                    module={props.relateDef?.get?.('module')}
                    data={{
                        value: defaultValue || '',
                    }}
                    updateField={false}
                />
            </React.Fragment>
        );
    }
}
