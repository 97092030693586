import React from 'react'
import PureComponent from '../../pure'

import QProductGroup from './QProductGroup'
import QTotal from './QTotal'

import {DragDropContext, Droppable} from "react-beautiful-dnd";
import sAction from "../../../action";

class ProductComponent extends PureComponent {
    onDragEnd = (dragEnd) => {
        if (!dragEnd.destination || !dragEnd.source) {
            return
        }

        if(dragEnd.source.droppableId === "groupDrag"){
            let allGroups = sAction.dataGet(`${this.props.way}/groups`)
            let sourceGroup = allGroups.get(dragEnd.source.index)
            allGroups = allGroups.delete(dragEnd.source.index).insert(dragEnd.destination.index, sourceGroup)
            sAction.dataSet(`${this.props.way}/groups`, allGroups)
            sAction.dataSet(`${this.props.way.split('/')[0]}/changes/forceChange`, true)
            return;
        }

        this.switchLines(dragEnd)


    }

    switchLines(dragEnd){
        let sourceLines = sAction.dataGet(`${this.props.way}/groups/${dragEnd.source.droppableId}/lines`)
        let destLines = sAction.dataGet(`${this.props.way}/groups/${dragEnd.destination.droppableId}/lines`)
        let sourceLine = sourceLines.get(dragEnd.source.index)

        if (dragEnd.source.droppableId === dragEnd.destination.droppableId) {

            sourceLines = sourceLines.delete(dragEnd.source.index).insert(dragEnd.destination.index, sourceLine)
            sAction.dataSet(`${this.props.way}/groups/${dragEnd.destination.droppableId}/lines`, sourceLines)
            sAction.dataSet(`${this.props.way.split('/')[0]}/changes/forceChange`, true)
            return;
        }

        destLines = destLines.insert(dragEnd.destination.index, sourceLine)
        sourceLines = sourceLines.delete(dragEnd.source.index)

        sAction.dsClear()
        sAction.dsAdd("set", `${this.props.way.split('/')[0]}/changes/forceChange`, true)
        sAction.dsAdd("set", `${this.props.way}/groups/${dragEnd.destination.droppableId}/lines`, destLines)
        sAction.dsAdd("set", `${this.props.way}/groups/${dragEnd.source.droppableId}/lines`, sourceLines)
        sAction.dsProcess()
    }

    render() {
        const data = this.props.data;
        const way = this.props.way;
        const readonly = this.props.readonly;
        const rate = this.props.rate;

        return (
            <div className="detailCard detailViewTabContent detailQuoteProdComponent">
                <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart} onDragUpdate={this.onDragUpdate}>
                    <Droppable droppableId="groupDrag" type="GROUPS">
                        {provided => (
                            <div className="detailQuoteLinesWrapper" {...provided.droppableProps} ref={provided.innerRef}>
                                {data?.groups?.map((group, k) =>
                                    <QProductGroup data={group} key={k} ind={k} way={way + "/groups"}
                                                   currency_id={data.currency_id}
                                                   readonly={readonly} rate={rate}/>
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <QTotal data={data} way={way} readonly={readonly} rate={rate}/>
            </div>
        )
    }
}

export default ProductComponent